import { initializeApp, getApps } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
// import { getMessaging, getToken, onMessage } from 'firebase/messaging'; // Remove messaging import

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBczUqsaoTY0gHUo2VUMdrKhSU3M3ntqa4",
  authDomain: "driverpanion.firebaseapp.com",
  projectId: "driverpanion",
  storageBucket: "driverpanion",
  messagingSenderId: "230519529076",
  appId: "1:230519529076:web:324a2a5119af4c7a607b21",
};

// Initialize Firebase app only if it hasn't been initialized yet
const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
// const messaging = getMessaging(app); // Remove messaging initialization

// Check if using emulator
if (process.env.REACT_APP_USE_EMULATOR === "true") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// Comment out the notification request and token retrieval
/*
Notification.requestPermission().then((permission) => {
  if (permission === 'granted') {
    console.log('Notification permission granted.');
    getToken(messaging, { vapidKey: 'BKBHE91gsD4VS8w05lvOCB7PXmUzYNV-PFfhuuRTAbDdQudskVaaTW8mIgh0SG2dZ_nQG4rb5F_6EEwNj9_SOug' }).then((currentToken) => {
      if (currentToken) {
        console.log('FCM Token:', currentToken);
        // Call the Cloud Function to update FCM key
        const updateFcmKey = httpsCallable(functions, 'updateFcmKey');
        updateFcmKey({ fcmKey: currentToken })
          .then((result) => {
            console.log('FCM key updated successfully:', result.data);
          })
          .catch((error) => {
            console.error('Error updating FCM key:', error);
          });
      } else {
        console.log('No registration token available.');
      }
    }).catch((err) => {
      console.error('An error occurred while retrieving token.', err);
    });
  } else {
    console.log('Unable to get permission to notify.');
  }
});
*/

export { auth, db, storage, functions, httpsCallable, signInAnonymously };
