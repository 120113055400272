import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import DonationForm from '../components/DonationForm'; // Create a separate component for the donation form
// https://buy.stripe.com/28o2b54YiffQ3Ic5kl
// Stripe Promise
const stripePromise = loadStripe('YOUR_STRIPE_PUBLIC_KEY');
const PreRegisterPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Header Section */}
      <header className="bg-white shadow-md py-4">
        <div className="container mx-auto flex justify-between items-center px-4">
          <h1 className="text-2xl font-bold text-gray-800">Rideafide</h1>
          <a href="https://gofund.me/a3aff158" target="_blank" rel="noopener noreferrer">
  <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
    Support Us On GoFundMe
  </button>
</a>
        </div>
      </header>
     {/* Hero Section */}
<section className="bg-blue-600 text-white text-center py-20">
  <div className="container mx-auto px-4">
    <h2 className="text-4xl font-bold mb-4">Your Ride. Your Business. Simplified. Bonafide.</h2>
    <p className="text-xl mb-8">Rideafide is built by rideshare drivers, for rideshare drivers. This isn’t just another corporate platform—it’s a movement empowering drivers to take control. Join us and experience the freedom to run your business your way, with safety and personal connection at the forefront.</p>
    <p className="text-xl mb-8">
      We’re in the process of bringing this platform to life, and we need your support to make it happen! Every contribution helps us build a better future for drivers and riders alike. Whether it’s funding development, securing licenses, or ensuring the highest quality hosting, your donation will directly impact the success of Rideafide. Together, we can create a more fair, transparent, and empowering rideshare experience for everyone.
    </p>
    <p className="text-xl mb-8">
      As a thank you for your support, you’ll receive early access to the platform and a 50% discount on the subscription price for the lifetime of your first subscription. Don’t miss this chance to be part of something transformative from the very beginning!
    </p>
    <a href="https://gofund.me/a3aff158" target="_blank" rel="noopener noreferrer">
      <button className="bg-white text-blue-600 px-6 py-3 rounded-lg hover:bg-gray-200">
        Support Us on GoFundMe
      </button>
    </a>
    <p className="mt-8 text-lg">Interested in sponsoring or partnering with us? Email us at <a href="mailto:contact@rideafide.com" className="underline">contact@rideafide.com</a>.</p>
  </div>
</section>
{/* Progress Section */}
<section id="progress" className="bg-gray-300 py-16">
  <div className="container mx-auto px-4 text-center">
    <h3 className="text-3xl font-bold mb-6">Our Platform Development Progress</h3>
    <p className="text-xl mb-8 max-w-3xl mx-auto">
      We’ve made significant strides in building Rideafide, and we’re excited to share our progress with you! From designing intuitive user interfaces to implementing robust backend functionalities, our team has been working tirelessly to create a platform that truly empowers rideshare drivers.
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-left">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h4 className="text-xl font-semibold mb-4">User Interface</h4>
        <p>We’ve designed a clean, user-friendly interface that makes it easy for drivers and riders to navigate the platform and connect seamlessly.</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h4 className="text-xl font-semibold mb-4">Core Functionalities</h4>
        <p>Our team has implemented key features like profile sharing, in-app messaging, and service listings, providing drivers with the tools they need to manage their business effectively.</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h4 className="text-xl font-semibold mb-4">Security and Stability</h4>
        <p>We’re committed to ensuring a safe and reliable experience for all users. We’ve integrated robust security measures and optimized the platform for high performance and stability.</p>
      </div>
    </div>
    <p className="text-xl mt-8 max-w-3xl mx-auto">
      We still have a lot to accomplish, but with your support, we’re moving closer to a full launch. Stay tuned for more updates as we continue to build a platform that puts drivers first!
    </p>
  </div>
</section>

      {/* Key Points / The Difference Section */}
      <section id="key-points" className="container mx-auto px-4 py-16">
        <h3 className="text-3xl font-bold text-center mb-12">What Makes Us Different</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <img src='/icons/cash.png' alt="Cash Icon" className="w-16 h-16 mb-4" />
            <h4 className="text-xl font-semibold mb-2">You Keep Your Money!</h4>
            <p className="text-center">On Rideafide, you keep 100% of your earnings and set your own rates. All you pay is just $10/month, supporting continuous development and high-quality hosting for reliable service. No hidden fees, just freedom and control over your ride-share business.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <img src="/icons/unfair.png" alt="Unfair Icon" className="w-16 h-16 mb-4" />
            <h4 className="text-xl font-semibold mb-2">No More Unfair Treatment</h4>
            <p className="text-center">Say goodbye to unfair rating systems and unjust account suspensions. On Rideafide, you won't be blocked, suspended, or deleted for frivolous reasons. We believe in a fair, transparent platform where your income and reputation are protected, giving you the peace of mind to focus on delivering great service.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <img src="/icons/flex.png" alt="Flex Icon" className="w-16 h-16 mb-4" />
            <h4 className="text-xl font-semibold mb-2">Be your own boss!</h4>
            <p className="text-center">You’re not just giving rides—you’re building your own business. With Rideafide, you set the rules, choose your rates, and manage your schedule. We’re just the platform supporting your success. Take control, and drive your business forward!</p>
          </div>
        </div>
      </section>
      {/* Features Section */}
      <section id="features" className="container mx-auto px-4 py-16">
        <h3 className="text-3xl font-bold text-center mb-12">Features</h3>
        <p className="text-center mb-2 font-semibold">Note: For obvious reasons, we're not disclosing all of the features publicly.</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <img src="/icons/profilecard.png" alt="QR Code" className="h-16 w-16 mb-4" />
            <h4 className="text-xl font-semibold mb-4">Profile Sharing</h4>
            <p>Offer your services with ease using Rideafide. Share your profile with QR codes and links, making it simple to connect with riders—no awkward conversations or complicated exchanges. Whether you’re on a ride or off, promote your business effortlessly.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <img src="/icons/messages.png" alt="Messaging" className="h-16 w-16 mb-4" />
            <h4 className="text-xl font-semibold mb-4">In-app Messaging</h4>
            <p>Communicate with riders anytime, whether before, during, or after the ride—without sharing personal contact details. Our platform makes it easy for drivers and riders to stay connected, coordinate rides, and build lasting relationships, all through secure in-app messaging.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <img src="/icons/cars.png" alt="Messaging" className="h-16 w-16 mb-4" />
            <h4 className="text-xl font-semibold mb-4">Multiple Vehicles</h4>
            <p>Easily manage multiple vehicles on your profile. Add, update, and showcase all your vehicles to give riders more choices and flexibility when booking rides.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <img src="/icons/listing.png" alt="Messaging" className="h-16 w-16 mb-4" />
            <h4 className="text-xl font-semibold mb-4">Ride Listings</h4>
            <p>Riders can post the trips they need, allowing drivers to find and offer the perfect ride, making scheduling more convenient for everyone.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <img src="/icons/handshake.png" alt="Messaging" className="h-16 w-16 mb-4" />
            <h4 className="text-xl font-semibold mb-4">Service Offerings</h4>
            <p>Showcase your services! Drivers can list the services they offer—like luggage assistance, pet-friendly rides, trailer pulling, or premium options—making it easy for riders to find the perfect match for their needs.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <img src="/icons/bull.png" alt="No Bull" className="h-16 w-16 mb-4" />
            <h4 className="text-xl font-semibold mb-4">No Bullshit</h4>
            <p>No gimmicks, no upsells, no hidden fees, and no paying to boost your listing. We believe in a genuine, human-first experience. This platform was inspired by the maltreatment, disrespect, and lack of basic decency shown by the big rideshare companies. We knew there had to be a better way—so we built it.</p>
          </div>
        </div>
      </section>
      {/* FAQ Section */}
<section id="faq" className="bg-gray-200 py-16">
  <div className="container mx-auto px-4 text-center">
    <h3 className="text-3xl font-bold mb-12">Frequently Asked Questions</h3>
    <div className="space-y-8 max-w-3xl mx-auto text-left">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h4 className="text-xl font-semibold mb-4">What makes Rideafide different from other platforms?</h4>
        <p>Rideafide is built by rideshare drivers, for rideshare drivers. We prioritize your control and autonomy, without hidden fees or corporate interference. You keep 100% of your earnings and set your own rates.</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h4 className="text-xl font-semibold mb-4">How do I share my profile with riders?</h4>
        <p>You can easily share your profile using QR codes and links, making it simple to promote your services on the go, whether you’re currently driving or not.</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h4 className="text-xl font-semibold mb-4">Is there a fee to use Rideafide?</h4>
        <p>Yes, there’s a small fee of $10 per month to support ongoing development and ensure reliable, high-quality hosting. There are no upsells or hidden fees—just straightforward access to the platform.</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h4 className="text-xl font-semibold mb-4">How does messaging work on Rideafide?</h4>
        <p>Our in-app messaging allows you to communicate with riders anytime without sharing personal contact information, keeping conversations secure and hassle-free.</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h4 className="text-xl font-semibold mb-4">Won’t this upset companies like Uber?</h4>
        <p>Possibly, but we’re focused on creating a fair and empowering platform for drivers. If they’re affected, it’s a consequence of their own policies and actions.</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h4 className="text-xl font-semibold mb-4">What if they take legal action against you?</h4>
        <p>We’re rideshare drivers just like you, building something to make our work lives better. Any legal threats would only highlight the need for alternatives like Rideafide.</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h4 className="text-xl font-semibold mb-4">How can we be sure the platform will continue to be developed and completed?</h4>
        <p>As rideshare drivers ourselves, we’re building this platform to improve our own work experiences too. We’re committed to making it the best it can be because we’re just as invested and excited as you are to see it succeed.</p>
      </div>
    </div>
  </div>
</section>
      {/* Donation Box Section */}
      <section id="donate" className="bg-gray-100 py-16">
  <div className="container mx-auto px-4">
    <h3 className="text-4xl font-bold text-center mb-6">Support Our Mission</h3>
    <p className="text-lg text-center mb-8 max-w-3xl mx-auto">
      Your support helps us continue building Rideafide into the platform rideshare drivers truly deserve. Every contribution enables us to improve features, maintain high-quality services, and keep Rideafide running smoothly. Together, we can make a real difference in the rideshare community.
    </p>
    <div className="text-center">
      <a href="https://gofund.me/a3aff158" target="_blank" rel="noopener noreferrer">
        <button className="bg-blue-600 text-white text-lg font-semibold px-8 py-4 rounded-lg hover:bg-blue-700 transition duration-200">
          Get Early Access & Support Us
        </button>
      </a>
    </div>
  </div>
</section>
      {/* Footer Section */}
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Rideafide. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};
export default PreRegisterPage;
