import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { auth, db } from '../firebase'; // Import already initialized Firebase services
import { getDatabase, ref, onDisconnect, set, serverTimestamp, onValue } from 'firebase/database';
import { GeoPoint, collection, doc, setDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { GeoFirestoreClient } from 'geofirestore';
// Initialize GeoFirestore with Firestore
const geocollection = collection(db, 'locations'); // Reference the Firestore collection for geolocation data
const OnlineToggle = () => {
  const [isOnline, setIsOnline] = useState(false);
  const { currentUser } = useAuth();
  useEffect(() => {
    if (!currentUser) return;
    const realtimeDb = getDatabase();
    const userStatusRef = ref(realtimeDb, `/status/${currentUser.uid}`);
    const userLocationRef = doc(geocollection, currentUser.uid); // Reference to the user's document in GeoFirestore collection
    const handleOnlineToggle = () => {
      if (isOnline) {
        // Set user to online
        const onlineStatus = {
          state: 'online',
          last_changed: serverTimestamp(),
        };
        set(userStatusRef, onlineStatus);
        // Manage disconnection
        onDisconnect(userStatusRef).set({
          state: 'offline',
          last_changed: serverTimestamp(),
        });
        // Check for location permissions and update location
        if (navigator.geolocation) {
          let originalPoint = null; // Original point X
          navigator.geolocation.watchPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              // If originalPoint is null, set it to the current location
              if (!originalPoint) {
                originalPoint = { latitude, longitude };
                const newLocation = { coordinates: new GeoPoint(latitude, longitude) };
                setDoc(userLocationRef, newLocation);
                return;
              }
              // Calculate the distance from the original point
              const distance = calculateDistance(originalPoint.latitude, originalPoint.longitude, latitude, longitude);
              // If the user has moved more than 1/4 mile (0.25 miles)
              if (distance > 0.25) {
                originalPoint = { latitude, longitude }; // Update original point to new location
                const newLocation = { coordinates: new GeoPoint(latitude, longitude) }; // Use GeoPoint from Firestore
                setDoc(userLocationRef, newLocation); // Update location in GeoFirestore
              }
            },
            (error) => {
              if (error.code === error.PERMISSION_DENIED) {
                // Show modal if permission is denied
                const modal = document.createElement('div');
                modal.className = 'fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50';
                modal.innerHTML = `
                  <div class="bg-white p-6 rounded-md shadow-md relative">
                    <button class="absolute top-2 right-2 text-gray-600 text-xl font-bold" id="closeModalButton">&times;</button>
                    <h2 class="text-lg font-semibold mb-4">Location Permission Needed</h2>
                    <p class="mb-4">To appear online, please enable location permissions in your browser settings.</p>
                    <button class="bg-blue-500 text-white px-4 py-2 rounded" id="enableLocationButton">Enable Location</button>
                    <button class="bg-red-500 text-white px-4 py-2 rounded mt-2" id="doNotEnableButton">Do Not Enable</button>
                  </div>
                `;
                document.body.appendChild(modal);
                // Handle button click to enable location
                document.getElementById('enableLocationButton').addEventListener('click', () => {
                  modal.remove();
                  navigator.geolocation.getCurrentPosition(
                    (position) => {
                      const { latitude, longitude } = position.coords;
                      const newLocation = { coordinates: new GeoPoint(latitude, longitude) };
                      setDoc(userLocationRef, newLocation);
                    },
                    (error) => console.error('Error getting location:', error),
                    { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
                  );
                });
                // Handle button click to close modal
                document.getElementById('closeModalButton').addEventListener('click', () => modal.remove());
                document.getElementById('doNotEnableButton').addEventListener('click', () => modal.remove());
              } else {
                console.error('Error getting location:', error);
              }
            },
            { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
          );
        } else {
          alert('Geolocation is not supported by your browser.');
        }
      } else {
        // Set user to offline
        set(userStatusRef, {
          state: 'offline',
          last_changed: serverTimestamp(),
        });
      }
    };
    // Function to calculate distance between two points using the Haversine formula
    function calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 3958.8; // Radius of the Earth in miles
      const dLat = ((lat2 - lat1) * Math.PI) / 180;
      const dLon = ((lon2 - lon1) * Math.PI) / 180;
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
          Math.cos((lat2 * Math.PI) / 180) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // Distance in miles
    }
    handleOnlineToggle();
    // Ensure button reflects online status
    const statusRef = ref(realtimeDb, `/status/${currentUser.uid}`);
    onValue(statusRef, (snapshot) => {
      const status = snapshot.val();
      if (status?.state !== 'online') {
        setIsOnline(false);
      }
    });

    // Clean up listener on component unmount
    return () => {
      set(userStatusRef, {
        state: 'offline',
        last_changed: serverTimestamp(),
      });
    };
  }, [currentUser, isOnline]);
  const handleToggle = () => {
    setIsOnline(!isOnline);
  };
  if (!currentUser) {
    return null;
  }
  return (
    <button
      onClick={handleToggle}
      className={`px-4 py-2 rounded-full font-bold transition-colors duration-300 ${isOnline ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-700'}`}
    >
      {isOnline ? 'Go Offline' : 'Go Online'}
    </button>
  );
};
export default OnlineToggle;
