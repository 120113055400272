import React, { useState, useEffect } from 'react';
import { functions } from '../../firebase.js';
import { httpsCallable } from 'firebase/functions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../../components/PaymentForm'; // Assume you have a separate PaymentForm component
const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripeKey); // Replace with your Stripe publishable key
const SubScribePage = () => {
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  // Check if they have a subscription, if they have a cust_id
  useEffect(() => {
    const preSubscribe = httpsCallable(functions, 'preSubscribe');
    preSubscribe()
      .then((result) => {
        console.log(result.data);
        setClientSecret(result.data.client_secret);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const options = {
    clientSecret: clientSecret,
    appearance: {
      theme: 'flat',
    },
  };
  return (
    <div className='w-full max-w-sm bg-white p-4 shadow-md rounded-lg mx-auto'>
      <span>Subscribe Now</span>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <PaymentForm />
        </Elements>
      ) : (
        <p>Loading payment form...</p>
      )}
    </div>
  );
};
export default SubScribePage;
